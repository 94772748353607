import { createEmptyWeek } from '@spike/model';
import _ from 'lodash';
import {
    Staff,
    StaffCustomDaySlot,
    StaffEmergencyContact,
    StaffSchedule,
    StaffScheduleCustomDay,
    StaffService,
    StaffSlots,
    StaffWorkAt,
    StaffDefaultSlot
} from 'model/Staff';
import moment from 'moment';
import store from 'store';
import { CustomDaySlotDto, CustomizedScheduleDto, DefaultSlotDto, PerformDto, StaffDto, WorkDto } from './StaffDto';
import { v4 as uuid } from 'uuid';

export const convertStaff = (staffDto: StaffDto): Staff => {
    const masterData = store.getState().masterData.data;
    const marketplaceId = store.getState().marketplace.marketplace.id || store.getState().login.auth.marketplaceId;
    const timeZone = store.getState().marketplace.marketplace.schedule.timeZone;
    const workMarketplace = staffDto.works.find(w => w.marketplace_id === marketplaceId);

    return {
        id: staffDto.id,
        uuid: staffDto.uuid === null ? staffDto.id.toString() : staffDto.uuid, //Hack
        person: {
            firstName: _.capitalize(staffDto.first_name),
            lastName: _.capitalize(staffDto.last_name),
            avatar: staffDto.image,
            birthdate: staffDto.birthday ? moment(staffDto.birthday).tz('utc') : undefined,
            address: staffDto.address || '',
            phoneNumber: staffDto.phone,
            email: staffDto.email
        },
        businessAreas: masterData.businessAreas.filter(ba => staffDto.business_area_list.includes(ba.id)),
        accessLevel: masterData.accessLevel.find(
            accessLevel =>
                accessLevel.id === staffDto.works.find(w => w.marketplace_id === marketplaceId)?.access_level_id
        ),
        role: masterData.roles.find(
            role => role.id === staffDto.works.find(w => w.marketplace_id === marketplaceId)?.role_id
        ),
        emergencyContact:
            staffDto.emergency_contacts && staffDto.emergency_contacts.length > 0
                ? {
                      id: staffDto.emergency_contacts[staffDto.emergency_contacts.length - 1]?.id,
                      firstName: _.capitalize(
                          staffDto.emergency_contacts[staffDto.emergency_contacts.length - 1]?.first_name || ''
                      ),
                      lastName: _.capitalize(
                          staffDto.emergency_contacts[staffDto.emergency_contacts.length - 1]?.last_name || ''
                      ),
                      email: staffDto.emergency_contacts[staffDto.emergency_contacts.length - 1]?.email || '',
                      phoneNumber: staffDto.emergency_contacts[staffDto.emergency_contacts.length - 1]?.phone || '',
                      relationship: masterData.relationships.find(
                          r =>
                              r.id ===
                              staffDto.emergency_contacts[staffDto.emergency_contacts.length - 1].relationship_id
                      )
                  }
                : {
                      firstName: '',
                      lastName: '',
                      email: '',
                      phoneNumber: '',
                      relationship: undefined
                  },
        services: convertService(staffDto.performs),
        notifications: {
            phone: staffDto.phone_notifications,
            email: staffDto.email_notifications
        },
        schedule: convertSchedule(workMarketplace, timeZone),
        active: staffDto.active !== undefined && staffDto.works.find(w => w.marketplace_id === marketplaceId)!.active,
        destroyable: staffDto.destroyable,
        worksAt: staffDto.works ? staffDto.works.map(w => convertWork(w)) : [],
        createdAt: moment(staffDto.created_at),
        deleted: staffDto.deleted,
        last_login: staffDto.last_login ? moment(staffDto.last_login) : null,
        slots: convertSlots(workMarketplace, timeZone)
    };
};

const convertService = (performDtos: Array<PerformDto>): Array<StaffService> => {
    return performDtos.map(perform => ({
        id: perform.service_id,
        performId: perform.id,
        uuid: perform.service_uuid,
        name: perform.service_name
    }));
};

const convertWork = (workDto: WorkDto): StaffWorkAt => ({
    id: workDto.id,
    marketplaceId: workDto.marketplace_id,
    staffId: workDto.staff_id,
    roleId: workDto.role_id,
    accessLevelId: workDto.access_level_id,
    active: workDto.active
});

const convertSchedule = (workDto: WorkDto | undefined, timeZone: string): StaffSchedule => {
    if (workDto) {
        return {
            workId: workDto.id,
            calendarId: workDto.calendar?.id,
            default: workDto.calendar ? JSON.parse(workDto.calendar.range) : createEmptyWeek(),
            customDays: workDto.customize_schedules.map(customDay => convertCustomDay(customDay, timeZone))
        };
    } else {
        return {
            default: createEmptyWeek(),
            customDays: []
        };
    }
};

const convertCustomDay = (dto: CustomizedScheduleDto, timeZone: string): StaffScheduleCustomDay => {
    return {
        id: dto.id,
        uuid: dto.uuid,
        from: moment(dto.from).tz(timeZone),
        to: moment(dto.to).tz(timeZone),
        on: dto.on
    };
};

export const convertEmergencyContactToDto = (emergencyContact: StaffEmergencyContact): Array<any> => {
    if (isEmptyEmergencyContact(emergencyContact) && isSavedEmergencyContact(emergencyContact)) {
        return [{ id: emergencyContact.id, _destroy: true }];
    } else if (!isEmptyEmergencyContact(emergencyContact)) {
        return [
            {
                id: emergencyContact?.id,
                first_name: emergencyContact.firstName,
                last_name: emergencyContact.lastName,
                email: emergencyContact.email?.trim().toLowerCase(),
                phone: emergencyContact.phoneNumber,
                relationship_id: emergencyContact.relationship?.id
            }
        ];
    } else {
        return [];
    }
};

const convertSlots = (workDto: WorkDto | undefined, timeZone: string): StaffSlots => {
    const customDaySlots = workDto?.calendar.specific_date_slots
        ? (JSON.parse(workDto.calendar.specific_date_slots) as Array<CustomDaySlotDto>)
        : [];

    return {
        default: workDto?.calendar.slots
            ? convertDefaultSlots(workDto.calendar.slots)
            : ([] as Array<StaffDefaultSlot>),
        customDays: customDaySlots.map((slot: CustomDaySlotDto) => convertCustomDaySlot(slot, timeZone))
    };
};

const convertDefaultSlots = (defaultSlots: string): Array<StaffDefaultSlot> => {
    try {
        const slots = JSON.parse(defaultSlots);

        return [
            ...(slots.sunday?.map((slot: DefaultSlotDto) => convertDefaultSlot(slot, 'sunday')) || []),
            ...(slots.monday?.map((slot: DefaultSlotDto) => convertDefaultSlot(slot, 'monday')) || []),
            ...(slots.tuesday?.map((slot: DefaultSlotDto) => convertDefaultSlot(slot, 'tuesday')) || []),
            ...(slots.wednesday?.map((slot: DefaultSlotDto) => convertDefaultSlot(slot, 'wednesday')) || []),
            ...(slots.thursday?.map((slot: DefaultSlotDto) => convertDefaultSlot(slot, 'thursday')) || []),
            ...(slots.friday?.map((slot: DefaultSlotDto) => convertDefaultSlot(slot, 'friday')) || []),
            ...(slots.saturday?.map((slot: DefaultSlotDto) => convertDefaultSlot(slot, 'saturday')) || [])
        ];
    } catch (error) {
        return [];
    }
};

const convertDefaultSlot = (slot: DefaultSlotDto, weekDay: string): StaffDefaultSlot => {
    return {
        weekDay,
        uuid: uuid(),
        time: slot.time,
        petsCount: slot.pet_count
    } as StaffDefaultSlot;
};

const convertCustomDaySlot = (slot: CustomDaySlotDto, timeZone: string): StaffCustomDaySlot => {
    const [hour, minute] = slot.time.split(':').map(Number);
    const date = moment(slot.date).tz(timeZone, true).set('hour', hour).set('minute', minute);

    return {
        date,
        uuid: uuid(),
        on: slot.on,
        time: slot.time,
        petsCount: slot.pet_count
    };
};

const isEmptyEmergencyContact = (emergencyContact: StaffEmergencyContact): boolean => {
    return (
        _.isEmpty(emergencyContact.firstName) &&
        _.isEmpty(emergencyContact.lastName) &&
        _.isEmpty(emergencyContact.phoneNumber) &&
        _.isEmpty(emergencyContact.email)
    );
};

const isSavedEmergencyContact = (emergencyContact: StaffEmergencyContact): boolean => {
    return emergencyContact.firstName !== undefined;
};
