import { FunctionComponent, useState, useEffect } from 'react';
import { Box, Avatar, ClickAwayListener, useMediaQuery, FormControlLabel } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import { Button, CheckboxCircle, Checkbox } from 'components/UI';
import _ from 'lodash';
import { Option } from '@spike/model';

interface FilterProps {
    label?: string;
    options: Array<Option<number>>;
    onSelect: (ids: Array<number | undefined>, all: boolean) => void;
    icon: IconDefinition;
    showLabelOnMobile?: boolean;
    className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            position: 'relative',
            display: 'flex',
            alignItems: 'center'
        },
        button: {
            [theme.breakpoints.down('md')]: {
                'width': 39,
                'height': 39,
                'padding': 0,
                'minWidth': 'unset',
                'borderColor': '#000',
                'borderRadius': '50%',

                '& span': {
                    margin: '0px !important'
                },
                '& svg': {
                    width: 13,
                    height: 'auto'
                },
                '& .MuiButton-label': {
                    fontSize: '10.5px'
                }
            },

            [theme.breakpoints.up('lg')]: {
                minWidth: '115px'
            },
            [theme.breakpoints.only('xl')]: {
                minWidth: '174px'
            }
        },
        buttonAvatar: {
            [theme.breakpoints.down('md')]: {
                width: '16px',
                height: '16px'
            },
            [theme.breakpoints.only('lg')]: {
                width: '21px',
                height: '21px'
            },
            [theme.breakpoints.only('xl')]: {
                width: '32px',
                height: '32px'
            }
        },
        spinnerContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            [theme.breakpoints.down('md')]: {
                height: '30px',
                width: '107px'
            },
            [theme.breakpoints.only('lg')]: {
                height: '40px',
                width: '143px'
            },
            [theme.breakpoints.only('xl')]: {
                height: '60px',
                width: '216px'
            }
        },
        spinner: {
            '& .MuiCircularProgress-root': {
                [theme.breakpoints.down('md')]: {
                    height: '15px !important',
                    width: '15px !important'
                },
                [theme.breakpoints.only('lg')]: {
                    height: '20px !important',
                    width: '20px !important'
                },
                [theme.breakpoints.only('xl')]: {
                    height: '30px !important',
                    width: '30px !important'
                }
            }
        },
        list: {
            position: 'absolute',
            borderWidth: '1px',
            borderStyle: 'solid',
            backgroundColor: 'white',
            zIndex: 10,
            [theme.breakpoints.down('md')]: {
                top: '45px',
                borderRadius: '10px',
                minWith: '107px',
                maxWidth: '315px'
            },
            [theme.breakpoints.only('lg')]: {
                top: '50px',
                borderRadius: '12px',
                minWidth: '143px',
                maxWidth: '394px'
            },
            [theme.breakpoints.only('xl')]: {
                top: '60px',
                borderRadius: '14px',
                minWidth: '216px',
                maxWidth: '531px'
            }
        },
        listHeader: {
            width: '100%',
            borderBottom: '1px solid #D3D3D3',
            [theme.breakpoints.down('md')]: {
                paddingTop: '13px',
                paddingBottom: '6px'
            },
            [theme.breakpoints.only('lg')]: {
                paddingTop: '16px',
                paddingBottom: '7px'
            },
            [theme.breakpoints.only('xl')]: {
                paddingTop: '20px',
                paddingBottom: '9px'
            }
        },
        listBody: {
            width: '100%',
            overflowY: 'scroll',
            [theme.breakpoints.down('md')]: {
                maxHeight: '135px'
            },
            [theme.breakpoints.only('lg')]: {
                maxHeight: '180px'
            },
            [theme.breakpoints.only('xl')]: {
                maxHeight: '273px'
            }
        },
        listFooter: {
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            borderBottom: '1px solid #D3D3D3',
            [theme.breakpoints.down('md')]: {
                paddingTop: '12px',
                paddingBottom: '15px',
                borderRadius: '9px'
            },
            [theme.breakpoints.only('lg')]: {
                paddingTop: '16px',
                paddingBottom: '20px',
                borderRadius: '12px'
            },
            [theme.breakpoints.only('xl')]: {
                paddingTop: '24px',
                paddingBottom: '30px',
                borderRadius: '20px'
            }
        },
        listPadding: {
            [theme.breakpoints.down('md')]: {
                paddingLeft: '14px',
                paddingRight: '14px'
            },
            [theme.breakpoints.only('lg')]: {
                paddingLeft: '18px',
                paddingRight: '18px'
            },
            [theme.breakpoints.only('xl')]: {
                paddingLeft: '28px',
                paddingRight: '28px'
            }
        },
        firstOption: {
            [theme.breakpoints.down('md')]: {
                paddingTop: '4px'
            },
            [theme.breakpoints.only('lg')]: {
                paddingTop: '5px'
            },
            [theme.breakpoints.only('xl')]: {
                paddingTop: '7px'
            }
        },
        allStaff: {
            paddingTop: '0px !important',
            paddingBottom: '0px !important'
        },
        option: {
            [theme.breakpoints.down('md')]: {
                paddingTop: '8px'
            },
            [theme.breakpoints.only('lg')]: {
                paddingTop: '11px'
            },
            [theme.breakpoints.only('xl')]: {
                paddingTop: '16px'
            },
            '& .MuiTypography-root': {
                fontWeight: 400,
                fontFamily: 'Poppins',
                [theme.breakpoints.down('md')]: {
                    fontSize: '10px',
                    lineHeight: '18px'
                },
                [theme.breakpoints.only('lg')]: {
                    fontSize: '13px',
                    lineHeight: '22px'
                },
                [theme.breakpoints.only('xl')]: {
                    fontSize: '16px',
                    lineHeight: '27px'
                }
            }
        },
        mobileList: {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 9999,
            display: 'flex',
            alignItems: 'flex-end',
            position: 'fixed',
            backgroundColor: 'rgba(0, 0, 0, 0.25)'
        },
        mobileListContainer: {
            width: '100%',
            flex: '0 0 100%',
            paddingTop: 20,
            paddingLeft: 16,
            paddingRight: 16,
            paddingBottom: 24,
            backgroundColor: '#fff',
            borderRadius: '20px 20px 0px 0px',
            boxShadow: '0px -10px 40px rgba(0, 0, 0, 0.0784314)'
        },
        mobileListChip: {
            width: 56,
            height: 6,
            display: 'block',
            borderRadius: 100,
            margin: '0px auto 15px',
            backgroundColor: '#D4D4D4'
        },
        mobileListItem: {
            'marginLeft': 0,
            'padding': '14px 0px',

            '& .MuiTypography-root': {
                display: 'block',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            }
        },
        mobileListDivider: {
            marginBottom: 14,
            borderBottom: 'solid 1px #D4D4D4'
        },
        mobileListButton: {
            'height': 55,
            'width': '100%',
            'marginTop': 10,
            'borderRadius': 30,

            '& span': {
                fontSize: 18,
                fontWeight: 600
            }
        }
    })
);

export const Filter: FunctionComponent<FilterProps> = props => {
    const classes = useStyles();

    const [showList, setShowList] = useState(false);
    const [buttonLabel, setButtonLabel] = useState(props.label ? 'All ' + props.label : 'All');
    const [buttonAvatar, setButtonAvatar] = useState<JSX.Element | undefined>();
    const [selected, setSelected] = useState<Array<number | undefined>>([...props.options.map(option => option.id)]);
    const [allSelected, setAllSelected] = useState(true);
    const [options, setOptions] = useState(props.options);

    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    useEffect(() => {
        const ids = props.options.map(option => option.id);
        const newIds = _.difference(
            props.options.map(option => option.id),
            options.map(option => option.id)
        );
        setSelected(prev => [...prev.filter(id => ids.includes(id!)), ...newIds]);
        setOptions(props.options);
    }, [props.options]);

    const closeHandler = () => {
        setShowList(false);
        const ids = props.options.map(option => option.id);
        const newIds = _.difference(
            props.options.map(option => option.id),
            options.map(option => option.id)
        );
        setSelected(prev => [...prev.filter(id => ids.includes(id!)), ...newIds]);
    };

    const clickButtonHandler = () => {
        setShowList(prev => !prev);
    };

    const selectOptionHandler = (checked: boolean, optionId: number | undefined) => {
        if (checked) {
            setAllSelected(selected.length === options.length);
            setSelected(prev => [...prev.filter(id => id !== optionId), optionId]);
        } else {
            setSelected(prev => prev.filter(id => id !== optionId));
            setAllSelected(false);
        }
    };

    const clickAllHandler = (checked: boolean) => {
        setAllSelected(checked);
        if (checked) {
            setSelected([...options.map(option => option.id)]);
        } else {
            setSelected([]);
        }
    };

    const clickApplyHandler = () => {
        if (allSelected || selected.length === 0) {
            setButtonLabel(props.label ? 'All ' + props.label : 'All');
            setButtonAvatar(undefined);
        } else if (selected.length === 1 && selected[0] !== undefined) {
            const option = options.find(option => option.id === selected[0])!;
            setButtonLabel(option.name);
            setButtonAvatar(
                props.label ? undefined : (
                    <Avatar
                        src={option.description}
                        className={classes.buttonAvatar}
                    />
                )
            );
        } else if (selected.length === 1 && selected[0] === undefined) {
            setButtonLabel(`Unassigned`);
            setButtonAvatar(undefined);
        } else {
            setButtonLabel(
                props.label
                    ? `${props.label} (${selected.filter(id => id !== undefined).length})`
                    : `Staff (${selected.filter(id => id !== undefined).length})`
            );
            setButtonAvatar(undefined);
        }
        props.onSelect(selected, allSelected);
        setShowList(false);
    };

    const buildLabel = () => {
        return props.label ? 'All ' + props.label : 'All';
    };

    const list = (
        <Box className={classes.list}>
            <Box className={clsx(classes.listHeader, classes.listPadding)}>
                <CheckboxCircle
                    label={buildLabel()}
                    checked={allSelected}
                    onChange={clickAllHandler}
                    className={clsx(classes.option, classes.allStaff)}
                />
            </Box>
            <Box className={clsx(classes.listBody, classes.listPadding)}>
                {options.map((option, index) => (
                    <CheckboxCircle
                        key={option.id}
                        label={option.name.length > 38 ? `${option.name.substring(0, 35)}...` : option.name}
                        checked={selected.includes(option.id)}
                        onChange={(checked: boolean) => {
                            selectOptionHandler(checked, option.id);
                        }}
                        className={clsx(classes.option, {
                            [classes.firstOption]: index === 0
                        })}
                    />
                ))}
            </Box>
            <Box className={clsx(classes.listFooter, classes.listPadding)}>
                <Button
                    label="Apply"
                    color="green"
                    onClick={clickApplyHandler}
                />
            </Box>
        </Box>
    );

    const mobileList = (
        <Box className={classes.mobileList}>
            <ClickAwayListener onClickAway={closeHandler}>
                <Box className={classes.mobileListContainer}>
                    <span className={classes.mobileListChip} />

                    <FormControlLabel
                        label={buildLabel()}
                        control={
                            <Checkbox
                                checked={allSelected}
                                onChange={clickAllHandler}
                            />
                        }
                        className={classes.mobileListItem}
                    />

                    <Box className={classes.mobileListDivider} />

                    {options.map(option => (
                        <Checkbox
                            key={option.id}
                            label={`${option.name}`}
                            checked={selected.includes(option.id)}
                            className={classes.mobileListItem}
                            onChange={(checked: boolean) => {
                                selectOptionHandler(checked, option.id);
                            }}
                        />
                    ))}

                    <Button
                        label="Apply"
                        color="green"
                        className={classes.mobileListButton}
                        onClick={clickApplyHandler}
                    />
                </Box>
            </ClickAwayListener>
        </Box>
    );

    return (
        <ClickAwayListener onClickAway={closeHandler}>
            <Box className={clsx(classes.container, props.className)}>
                <Button
                    label={
                        !props.showLabelOnMobile && isMobile
                            ? ''
                            : isMobile
                            ? buttonLabel.length > 9
                                ? buttonLabel.substring(0, 5) + '...'
                                : buttonLabel
                            : buttonLabel
                    }
                    startIcon={!buttonAvatar ? props.icon : undefined}
                    startElement={buttonAvatar}
                    onClick={clickButtonHandler}
                    variant="secondary"
                    color="green"
                    size={isMobile ? 'medium' : 'large'}
                    className={classes.button}
                />
                {showList && <>{isMobile ? mobileList : list}</>}
            </Box>
        </ClickAwayListener>
    );
};

export default Filter;
