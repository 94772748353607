import React, { useState } from 'react';
import { Box, Theme, createStyles, makeStyles } from '@material-ui/core';
import { ClientPaymentMethod, MarketplacePaymentMethod } from '@spike/payments-model';
import AddCreditCard from 'components/ClientCards/Card/AddCreditCard';
import { CardRow } from 'components/Marketplace/BusinessSettings/Billing/Card/CardRow';

type CardType = MarketplacePaymentMethod | ClientPaymentMethod;
interface CardGroupProps {
    cards: Array<ClientPaymentMethod>;
    onError: (error: string | undefined) => void;
    hideDefaultBadge?: boolean;
    onDeleteCard?: (card: ClientPaymentMethod) => void;
    hideEditButton?: boolean;
    className?: string;
    clientId: number;
    showAddCard?: boolean;
    onCloseAddCard?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            borderRadius: 18,
            [theme.breakpoints.up('sm')]: {
                padding: '6px 32px',
                border: 'solid 1px #DDDDDD'
            }
        }
    })
);

export const CardGroup: React.FunctionComponent<CardGroupProps> = props => {
    const classes = useStyles();
    const [activeId, setActiveId] = useState<null | number>(null);

    const openDropdownHandler = (id: number) => {
        if (id === activeId) {
            setActiveId(null);
            return;
        }
        setActiveId(id);
    };

    return (
        <>
            <Box className={classes.root + ' ' + props.className}>
                {props.cards.map((card, i) => (
                    <CardRow
                        id={i}
                        key={i}
                        card={card}
                        active={false}
                        onOpenDropdown={openDropdownHandler}
                        hideDefaultBadge={props.hideDefaultBadge}
                        hideEditButton={props.hideEditButton}
                        onDeleteCard={props.onDeleteCard}
                        showExpired={true}
                    />
                ))}
            </Box>
        </>
    );
};
