import React, { useEffect, useState } from 'react';
import { Box, Grid, Theme, Typography, createStyles, makeStyles } from '@material-ui/core';
import { BusinessSettingsTitle } from '../UI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import { CardGroup } from './Card/CardGroup';
import { InvoicesList } from './Invoices/InvoicesList';
import { usePremiumPlan } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { MarketplacePaymentMethod } from '@spike/payments-model';
import {
    fetchSubscriptionInvoicesThunk,
    getSubscriptionPaymentMethodThunk,
    resetSubscriptionPaymentMethod
} from 'actions/subscriptions/SubscriptionsActions';
import { AdminInvoice } from 'model/Subscriptions';
import { MobileDivider } from 'components/UI/MobileDivider';
import DeclinedCardAlert from 'components/UI/V2/Info/Variants/DeclinedCardAlert';
import { SubscriptionsState, SubscriptionsStatus } from 'reducers/subscriptions/SubscriptionsState';
import { useNonInitialEffect } from '@versiondos/hooks';
import Spinner from 'components/UI/Spinner';

export interface Props {
    openSidebar?: () => void;
    isMobile?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%'
        },
        container: {
            width: '100%',
            paddingRight: 16,

            [theme.breakpoints.up('md')]: {
                paddingRight: 45
            }
        },
        listGrid: {
            [theme.breakpoints.up('sm')]: {
                marginBottom: 30
            },
            [theme.breakpoints.up('md')]: {
                marginBottom: 60
            },

            '& h3': {
                color: '#222',
                fontSize: 16,
                lineHeight: 1,
                fontWeight: 600,
                marginBottom: 14
            },
            '& .MuiTypography-body2': {
                margin: 0,
                fontSize: 16,
                lineHeight: 1.4
            }
        },
        emptyBox: {
            width: '100%',
            fontSize: 16,
            lineHeight: 1,
            fontWeight: 500,
            borderRadius: 18,

            [theme.breakpoints.down('md')]: {
                marginBottom: 20
            },

            [theme.breakpoints.up('md')]: {
                padding: '29px 24px',
                border: 'solid 1px #DDDDDD'
            }
        },
        invoicesTitle: {
            fontSize: 16,
            lineHeight: 2,
            fontWeight: 600,
            marginBottom: 14
        },
        cardTitle: {
            fontSize: 16,
            lineHeight: 2,
            fontWeight: 600
        },
        cardText: {
            fontSize: 16
        },
        invoicesEmpty: {
            'fontSize': 16,
            'lineHeight': 1,
            'fontWeight': 500,

            '& svg': {
                marginRight: 9,
                color: '#5E8677'
            }
        },
        errorText: {
            'fontSize': 16,
            'fontWeight': 500,
            '& strong': {
                fontWeight: 600
            }
        }
    })
);

export const Billing: React.FunctionComponent<Props> = ({ openSidebar, isMobile }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const premiumPlan = usePremiumPlan();

    const invoices = useSelector<RootState, Array<AdminInvoice>>(state => state.subscriptions.invoices);

    const paymentMethod = useSelector<RootState, MarketplacePaymentMethod | undefined>(
        state => state.subscriptions.paymentMethod
    );

    const subscriptionStatus = useSelector<RootState, SubscriptionsStatus>(state => state.subscriptions.status);

    const [error, setError] = useState<string | undefined>(undefined);

    const [loading, setLoading] = useState<{ invoices: boolean; paymentMethods: boolean }>({
        invoices: true,
        paymentMethods: true
    });

    useEffect(() => {
        dispatch(fetchSubscriptionInvoicesThunk());
    }, []);

    useEffect(() => {
        if (premiumPlan?.id) {
            dispatch(getSubscriptionPaymentMethodThunk(premiumPlan.id));
        }
    }, [premiumPlan]);

    useNonInitialEffect(() => {
        if (subscriptionStatus === SubscriptionsStatus.FetchInvoicesSuccess) {
            setLoading(prev => ({ ...prev, invoices: false }));
            if (!premiumPlan) {
                setLoading(prev => ({ ...prev, paymentMethods: false }));
            }
        }
        if (subscriptionStatus === SubscriptionsStatus.GetPaymentMethodSuccess) {
            setLoading(prev => ({ ...prev, paymentMethods: false }));
        }
    }, [subscriptionStatus]);

    const NoResultsBox = ({ message }: { message: string }) => <Box className={classes.emptyBox}>{message}</Box>;

    const InvoicesEmpty = () => (
        <Typography className={classes.invoicesEmpty}>
            <FontAwesomeIcon icon={faInfoCircle} /> No invoices found.
        </Typography>
    );

    const errorHandler = (error: string | undefined) => {
        setError(error);
    };

    const updateCardSuccessHandler = () => {
        setLoading(prev => ({ ...prev, paymentMethods: true }));
        dispatch(resetSubscriptionPaymentMethod());
        setError(undefined);
        setTimeout(() => {
            if (premiumPlan && premiumPlan.id) {
                dispatch(getSubscriptionPaymentMethodThunk(premiumPlan.id));
            }
        }, 5000);
    };

    const errorContent = (
        <>
            <strong>Your card was declined.</strong> Please try another card or contact support.{' '}
            <strong>
                <u>Your current payment method remains active.</u>
            </strong>
        </>
    );

    return (
        <Box className={classes.root}>
            <Box className={classes.container}>
                <BusinessSettingsTitle>
                    {isMobile && (
                        <FontAwesomeIcon
                            onClick={openSidebar}
                            icon={faArrowLeft}
                            size="2x"
                        />
                    )}
                    Billing
                </BusinessSettingsTitle>

                <Grid
                    container
                    spacing={3}
                    className={classes.listGrid}
                >
                    {error && (
                        <Grid
                            item
                            xs={12}
                            md={12}
                        >
                            <DeclinedCardAlert content={errorContent} />
                        </Grid>
                    )}
                    <Grid
                        item
                        xs={11}
                        md={4}
                    >
                        <Typography
                            variant="h3"
                            className={classes.cardTitle}
                        >
                            Card Details
                        </Typography>
                        <Typography
                            variant="body2"
                            className={classes.cardText}
                        >
                            This card will be used for billing purposes & recurring fees if applicable.
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md
                    >
                        {!loading.paymentMethods ? (
                            paymentMethod !== undefined ? (
                                <CardGroup
                                    cards={[paymentMethod]}
                                    onError={errorHandler}
                                    onUpdateCardSuccess={updateCardSuccessHandler}
                                />
                            ) : (
                                <NoResultsBox message="No credit card on file." />
                            )
                        ) : (
                            <Box
                                border={isMobile ? 'none' : 'solid 1px #DDDDDD'}
                                borderRadius={16}
                                padding={3}
                            >
                                <Spinner />
                            </Box>
                        )}
                    </Grid>
                </Grid>
            </Box>
            <MobileDivider style={{ marginTop: 10 }} />

            <Box className={classes.container}>
                <Typography
                    variant="h3"
                    className={classes.invoicesTitle}
                >
                    Billing-Invoices & Fees
                </Typography>

                {invoices.length === 0 && !loading.invoices && <InvoicesEmpty />}
            </Box>
            {invoices.length > 0 && !loading.invoices && <InvoicesList invoices={invoices} />}
            {loading.invoices && <Spinner />}
        </Box>
    );
};
