import { Box } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Appointment, { status } from '@spike/appointment-model';
import { Booking, BookingWrapper, SortFields, SortOrder } from '@spike/booking-model';
import {
    AppointmentsStatus,
    assignAppointmentStaffThunk,
    BookingsStatus,
    cancelAppointmentThunk,
    cancelBookingThunk,
    cancelRecurrentAppointmentsThunk,
    changeStatusAppointmentThunk,
    completeAppointmentThunk,
    confirmBookingThunk,
    Counter,
    declineAppointmentThunk,
    fetchMergeableAppointmentsThunk,
    refetchAppointmentsThunk,
    refetchBookingsThunk
} from '@spike/bookings-action';
import {
    allView,
    BookingsView,
    cancelledView,
    completedView,
    filter,
    getAssignedStaff,
    load as loadBookingsAppointments,
    pendingView,
    sort,
    Sorting,
    staffIssuesView,
    upcomingView,
    views
} from '@spike/bookings-view';
import { convertToYMDWithoutTimeZone, getTodayAsMoment, getTodayAsYMD } from '@spike/date-utils';
import { Period } from '@spike/model';
import { useNonInitialEffect } from '@versiondos/hooks';
import BookingDetail from 'components/BookingDetail2';
import Checkout from 'components/Checkout';
import DisplayInvoice from 'components/Invoice/DisplayInvoice';
import PetCard from 'components/PetCard';
import { CompleteReport, EditReport, ViewReport } from 'components/Reports';
import { OverDetail, OverFullWindow, Spinner, ConfirmDialog, Button, CompleteAppointmentDialog } from 'components/UI';
import Pagination from 'components/UI/Pagination';
import PopUpCancelBooking from 'components/UI/PopUpCancelBooking';
import {
    useApiClientWrapper,
    useHasPremiumAccess,
    useHubspotTrackingCode,
    useTimeZone,
    useMarketplace,
    useAuth
} from 'hooks';
import Pet from '@spike/pet-model';
import { AvailableStaff } from '@spike/available-staff-model';
import moment from 'moment';
import Client from 'pages/Client';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { wbp } from 'Theme';
import BookingsNotCreated from './BookingsNotCreated';
import BookingsHeader from './Header';
import StaffNotSelected from './StaffNotSelected';
import Table from './Table';
import { BookingsViewData } from 'model/BookingsPath';
import * as amplitude from '@amplitude/analytics-browser';
import { AMPLITUDE } from 'constants/index';
import CreateBookingDrawer from 'components/UI/CreateBookingDrawer';
import { Client as ClientModel } from '@spike/client-model';
import { NewBookingsStatus } from '@spike/new-bookings-action';
import { v4 as uuid } from 'uuid';
import { ClientsState, ClientsStatus, getClientThunk } from '@spike/clients-action';
import { CancellationModal } from 'components/UI/V2/CancellationModal';
import { isEmpty } from 'lodash';
import { OWNER_ID } from '@spike/model';
interface BookingsComponentProps {
    appointment?: Appointment | undefined;
    booking?: Booking | undefined;
    viewData?: BookingsViewData | null;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            position: 'relative',
            borderRadius: '18px' /* for content appointment inside client detail */
        },
        iframe: {
            width: '100%',
            height: '98%',
            border: 'none'
        },
        headerContainer: {
            display: 'flex',
            width: '100%'
        },
        boxBookingContainer: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            paddingBottom: '30px',
            width: '100%',
            height: '100px',
            background: 'white',
            overflowY: 'auto',
            borderRadius: '0 0 18px 18px' /* for content appointment inside client detail */,
            [theme.breakpoints.down(wbp)]: {
                marginTop: '32px'
            },
            [theme.breakpoints.up(wbp)]: {
                marginTop: '40px'
            },
            [theme.breakpoints.only('xl')]: {
                paddingBottom: '80px'
            }
        },
        tableContainer: {
            width: '100%',
            flexGrow: 1
        },
        pagination: {
            [theme.breakpoints.down('sm')]: {
                paddingRight: '9px'
            },
            [theme.breakpoints.only('md')]: {
                paddingRight: '15px'
            },
            [theme.breakpoints.only('lg')]: {
                paddingRight: '20px'
            },
            [theme.breakpoints.only('xl')]: {
                paddingRight: '30px'
            }
        },
        paginationFull: {
            background: '#F2F2F2 !important',
            [theme.breakpoints.down('sm')]: {
                paddingTop: '5px',
                paddingRight: '9px'
            },
            [theme.breakpoints.only('md')]: {
                paddingTop: '10px',
                paddingRight: '15px'
            },
            [theme.breakpoints.only('lg')]: {
                paddingTop: '15px',
                paddingRight: '20px'
            },
            [theme.breakpoints.only('xl')]: {
                paddingTop: '20px',
                paddingRight: '30px'
            }
        },
        dialog: {
            '& .MuiDialogContent-root': {
                padding: '0px'
            }
        },
        dialogClient: {
            border: '5px solid #000',
            padding: '0px',
            display: 'absolute',
            marginLeft: '-240px',
            [theme.breakpoints.only('xl')]: {
                marginLeft: '-330px'
            }
        },
        containerMedicalForm: {
            marginTop: '15px'
        },
        clientOverWindow: {
            zIndex: 10
        }
    })
);

interface CompleteReportData {
    appointmentId?: number;
}

interface Report {
    appointmentId: number;
    businessAreaId: string;
    onlyAddReport?: boolean;
    sendSms?: boolean;
    clientId?: number;
    sent?: boolean;
}

export const BookingsComponent: FunctionComponent<BookingsComponentProps> = props => {
    const classes = useStyles();

    const apiClientWrapper = useApiClientWrapper();
    const auth = useAuth();

    const dispatch = useDispatch();
    const timeZone = useTimeZone();
    const hasReportsPremiumAccess = useHasPremiumAccess('groomming_reports');

    const isOwner = auth.user?.role.id === OWNER_ID;

    const { setTrackEvent } = useHubspotTrackingCode();

    const appointments = useSelector<RootState, Array<Appointment>>(state => state.appointments.appointments)
        .filter(appointment => appointment.status.id !== status.BOOKED)
        .map(appointment => ({
            appointment,
            key: appointment.uuid,
            sort: {
                id: appointment.id!,
                clientName: `${appointment.customer.firstName} ${appointment.customer.lastName}`,
                petName: appointment.pet.name,
                petBreed: appointment.pet.breed?.name,
                petType: appointment.pet.type.singular,
                service: appointment.services[0].name,
                staff: `${appointment.services[0].staff?.firstName} ${appointment.services[0].staff?.lastName}`,
                dateTime: appointment.duration.from,
                total: appointment.total,
                status: appointment.status.id
            }
        }));

    const counter = useSelector<RootState, Counter>(state => state.appointments.counter);

    const mergeableAppointments = useSelector<RootState, Array<Appointment>>(state => state.appointments.mergeables);

    const appointmentsStatus = useSelector<RootState, AppointmentsStatus>(state => state.appointments.status);

    const bookings = useSelector<RootState, Array<Booking>>(state => state.bookings.bookings).map(booking => ({
        booking,
        key: booking.uuid,
        sort: {
            id: booking.id!,
            clientName: `${booking.appointments[0].customer.firstName} ${booking.appointments[0].customer.lastName}`,
            clientEmail: booking.appointments[0].customer.email,
            petName: booking.appointments[0].pet.name,
            petBreed: booking.appointments[0].pet.breed.name,
            petType: booking.appointments[0].pet.type.singular,
            service: booking.appointments[0].services[0].name,
            staff: `${booking.appointments[0].services[0].staff?.firstName} ${booking.appointments[0].services[0].staff?.lastName}`,
            dateTime: booking.bookedAt,
            total: booking.appointments[0].total,
            status: booking.appointments[0].status.id,
            appointmentId: booking.appointments[0].id
        }
    }));

    const bookingsStatus = useSelector<RootState, BookingsStatus>(state => state.bookings.status);

    const newBookingsStatus = useSelector<RootState, NewBookingsStatus>(state => state.newBookings.status);

    const marketplace = useMarketplace();

    const [loading, setLoading] = useState({
        appointments: true,
        bookings: true,
        any: true
    });

    const today = getTodayAsYMD(timeZone);
    const [from, setFrom] = useState(today);
    const [to, setTo] = useState(today);

    const [datePeriod, setDatePeriod] = useState<Period>({
        from: moment().tz(timeZone),
        to: moment().tz(timeZone)
    });
    const [view, setView] = useState(upcomingView);
    const [selectedStaff, setSelectedStaff] = useState<Array<number | undefined>>([]);
    const [selectedAllStaff, setSelectedAllStaff] = useState(true);
    const [searchText, setSearchText] = useState('');
    const [sorting, setSorting] = useState<Sorting | undefined>(undefined);
    const [decline, setDecline] = useState<Report | undefined>(undefined);
    const [checkoutAppointmentIds, setCheckoutAppointmentIds] = useState<Array<number>>([]);
    const [checkoutBookingId, setCheckoutBookingId] = useState<number | undefined>();
    const [customerID, setCustomerID] = useState<number | undefined>(undefined);
    const [customerName, setCustomerName] = useState<string | undefined>(undefined);
    const [petID, setPetID] = useState<number | undefined>(undefined);
    const [clientID, setClientID] = useState<number | undefined>(undefined);
    const [viewClientId, setViewClientId] = useState<number | undefined>(undefined);
    const [rescheduleAppointmentId, setRescheduleAppointmentId] = useState<number | undefined>(undefined);
    const [rescheduleBooking, setRescheduleBooking] = useState<BookingWrapper | undefined>(undefined);
    const [petCardId, setPetCardId] = useState<number | undefined>(undefined);

    const [bookingDelete, setBookingDelete] = useState<Booking>();
    const [appointmentDelete, setAppointmentDelete] = useState<Appointment>();

    const [editReport, setEditReport] = useState<Report | undefined>(undefined);

    const [invoiceIdToShow, setInvoiceIdToShow] = useState<number | undefined>();

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const [showNewClient, setShowNewClient] = useState(false);
    const [showNewBooking, setShowNewBooking] = useState(false);
    const [showEditReport, setShowEditReport] = useState(false);
    const [showViewReport, setShowViewReport] = useState(false);
    const [showBooking, setShowBooking] = useState<boolean>(false);
    const [showCancelBooking, setShowCancelBooking] = useState(false);
    const [rebookBooking, setRebookBooking] = useState<Booking | undefined>(undefined);

    const statusToNotAddReport = [status.CANCELLED, status.NO_SHOW];

    const [showReport, setShowReport] = useState<Report | undefined>(undefined);

    const [bookingId, setBookingId] = useState<number | undefined>(undefined);
    const [appointmentId, setAppointmentId] = useState<number | undefined>(undefined);
    const [showAddReport, setShowAddReport] = useState<Report | undefined>(undefined);

    const [showModalCheckout, setShowModalCheckout] = useState(false);
    const [isNewBooking, setIsNewBooking] = useState(false);
    const [petAdded, setPetAdded] = useState(false);
    const [clientAdded, setClientAdded] = useState(false);
    const [showClient, setShowClient] = useState(false);
    const [showModalCompleteAppointment, setShowModalCompleteAppointment] = useState(false);
    const [completeReportData, setCompleteReportData] = useState<CompleteReportData>({
        appointmentId: undefined
    });

    const client = useSelector<RootState, ClientModel | undefined>(state => state.clients.client);
    const emailConfigured = client && client.email && client.email.length > 0 ? true : false;

    const clientState = useSelector<RootState, ClientsState | undefined>(state => state.clients);

    const [loadingBooking, setLoadingBooking] = useState<number | undefined>(undefined);

    const assignedStaff = useMemo(
        () =>
            getAssignedStaff(appointments, bookings).map(staff =>
                staff === undefined
                    ? {
                          id: undefined,
                          name: 'Unassigned',
                          avatar: ''
                      }
                    : {
                          id: staff.id,
                          name: staff.firstName,
                          avatar: staff.avatar
                      }
            ),
        [appointments, bookings]
    );
    useEffect(() => {
        load(view);
    }, []);

    useEffect(() => {
        if (props.viewData) {
            setBookingId(props.viewData.bookingId);
            setAppointmentId(props.viewData.appointmentId);
            setShowBooking(true);
        }
    }, [props.viewData]);

    useNonInitialEffect(() => {
        switch (bookingsStatus) {
            case BookingsStatus.ConfirmSuccess:
                setLoadingBooking(undefined);
                break;
            case BookingsStatus.FetchSuccess:
            case BookingsStatus.FetchPendingsSuccess:
            case BookingsStatus.FetchWithStaffIssuesSuccess:
            case BookingsStatus.Error:
                setLoading(prev => ({
                    ...prev,
                    bookings: false,
                    any: prev.appointments
                }));
                break;
        }
    }, [bookingsStatus]);

    useNonInitialEffect(() => {
        switch (appointmentsStatus) {
            case AppointmentsStatus.FetchSuccess:
            case AppointmentsStatus.FetchPendingsSuccess:
            case AppointmentsStatus.FetchWithStaffIssuesSuccess:
                setLoading(prev => ({
                    ...prev,
                    appointments: false,
                    any: prev.bookings
                }));
                break;
            case AppointmentsStatus.Error:
                setLoading(prev => ({
                    ...prev,
                    appointments: false,
                    any: prev.bookings
                }));
                break;
        }
    }, [appointmentsStatus]);

    /* Verifica el status del fetch de un booking para hacer checkout */
    useNonInitialEffect(() => {
        switch (appointmentsStatus) {
            case AppointmentsStatus.FetchMergeablesSuccess:
                const invalidCheckoutStatuIds = [
                    status.BOOKED,
                    status.CONFIRMED,
                    status.CHECKED_IN,
                    status.IN_PROGRESS
                ];
                const validCheckoutStatuIds = [status.READY_FOR_CHECK_OUT, status.PAYMENT];

                if (
                    mergeableAppointments.some(appointment => invalidCheckoutStatuIds.includes(appointment.status.id))
                ) {
                    setShowModalCheckout(true);
                } else {
                    const appointmentIdsForCheckout = mergeableAppointments
                        .filter(appointment => validCheckoutStatuIds.includes(appointment.status.id))
                        .map(appointment => appointment.id!);

                    setCheckoutAppointmentIds(appointmentIdsForCheckout);
                    setCheckoutBookingId(mergeableAppointments[0].bookingId);
                }
                break;
        }
    }, [appointmentsStatus]);

    useNonInitialEffect(() => {
        switch (newBookingsStatus) {
            case NewBookingsStatus.SingleRescheduleSuccess:
            case NewBookingsStatus.RecurrentRescheduleSuccess:
                setRescheduleAppointmentId(undefined);
                setShowBooking(false);
                break;
        }
    }, [newBookingsStatus]);

    useNonInitialEffect(() => {
        if (clientState?.status === ClientsStatus.GetSuccess) {
            // For now, we're keeping this effect to handle future implementation
            return;
        }
    }, [clientState]);

    const load = (view: BookingsView, period?: Period) => {
        setPage(1);
        setLoading(prev => ({
            ...prev,
            appointments: view.showAppointments || view.showAppointments || view.showAppointmentsWithStaffIssues,
            bookings: view.showBookings || view.showPendingBookings || view.showBookingsWithStaffIssues,
            count: true,
            any: true
        }));
        loadBookingsAppointments(
            apiClientWrapper,
            dispatch,
            view,
            period ||
                datePeriod || {
                    from: getTodayAsMoment(timeZone),
                    to: getTodayAsMoment(timeZone)
                }
        );
    };

    const selectionViewHandler = (selectedViewId: string) => {
        setPage(1);
        const selectedView = views.find(v => v.id === selectedViewId)!;
        load(selectedView);
        setView(selectedView);
    };

    const changePeriodHandler = (period: Period) => {
        const fromAsString = convertToYMDWithoutTimeZone(period.from);
        const toAsString = convertToYMDWithoutTimeZone(period.to);
        setDatePeriod(period);
        setFrom(fromAsString);
        setTo(toAsString);
        load(view, period);
    };

    const changeStatusHandler = (appointmentId: number, statusId: number) => {
        setPage(1);
        dispatch(changeStatusAppointmentThunk(apiClientWrapper, appointmentId, statusId));
    };

    const assignStaffHandler = (appointmentId: number, staff: AvailableStaff | undefined) => {
        dispatch(assignAppointmentStaffThunk(apiClientWrapper, appointmentId, staff));
    };

    const showBookingHandler = (bookingId: number, appointmentId: number) => {
        setShowBooking(true);
        setBookingId(bookingId);
        setAppointmentId(appointmentId);
    };

    const showAppointmentHandler = (appointmentId: number) => {
        const appointment = appointments.find(w => w.appointment!.id === appointmentId)?.appointment;

        setShowBooking(true);
        setBookingId(appointment?.bookingId);
        setAppointmentId(appointmentId);
    };

    const checkoutBackHandler = () => {
        setCheckoutAppointmentIds([]);
        setCheckoutBookingId(undefined);
    };

    const completeCheckoutHandler = () => {
        checkoutBackHandler();
    };

    const checkoutHandler = async (appointmentId: number) => {
        const appointment = appointments.find(w => w.appointment!.id === appointmentId)?.appointment;

        if (appointment) {
            dispatch(fetchMergeableAppointmentsThunk(apiClientWrapper, appointment));
        }

        amplitude.track(AMPLITUDE.CTA_CHECK_OUT_APPOINTMENT_BOOKING_LIST);
    };

    const confirmHandler = (bookingId: number) => {
        setLoadingBooking(bookingId);
        dispatch(confirmBookingThunk(apiClientWrapper, bookingId));
        amplitude.track(AMPLITUDE.BOOK_APPOINTMENT_CONFIRMED);
    };

    const showReportHandler = (appointmentId: number, businessAreaId: string, clientId?: number) => {
        dispatch(getClientThunk(apiClientWrapper, clientId!));
        setShowReport({ appointmentId, businessAreaId, clientId });
        setShowViewReport(true);
    };

    const backNewBookingHandler = () => {
        setShowNewBooking(false);
        setCustomerID(undefined);
        setPetID(undefined);
        setClientAdded(false);
        setRebookBooking(undefined);
        setPetAdded(false);
        setRescheduleAppointmentId(undefined);
        dispatch(refetchAppointmentsThunk);
        dispatch(refetchBookingsThunk);
    };

    const sortHandler = (field: SortFields, order: SortOrder) => {
        order !== SortOrder.DEFAULT ? setSorting({ field, order }) : setSorting(undefined);
        setPage(1);
    };

    const addAppointmentHandler = () => {
        amplitude.track(AMPLITUDE.CTA_ADD_APPOINTMENT);
        setShowNewBooking(true);
    };

    const bookHandler = (clientId: number, petId: number, name: string) => {
        setCustomerID(clientId);
        setPetID(petId);
        setCustomerName(name);
        setShowNewBooking(true);
    };

    const rebookHandler = (selectedBooking: Booking) => {
        const bookingToRebook: Booking = {
            ...selectedBooking,
            bookedAt: moment().tz(marketplace.schedule.timeZone),
            customer: selectedBooking.customer,
            id: undefined,
            createdAt: undefined,
            updatedAt: undefined,
            appointments: selectedBooking?.appointments.map(appointment => ({
                ...appointment,
                id: undefined,
                recurringUuid: undefined,
                uuid: uuid(),
                notes: '',
                reports: {
                    dentalReportId: undefined,
                    groomingReportId: undefined
                },
                duration: {
                    from: moment().tz(marketplace.schedule.timeZone),
                    to: moment()
                        .tz(marketplace.schedule.timeZone)
                        .add(appointment.duration.to.diff(appointment.duration.from))
                },
                createdAt: undefined,
                updatedAt: undefined
            }))
        };

        setRebookBooking(bookingToRebook);
        setShowBooking(false);
    };

    const rescheduleHandler = (appointmentId: number) => {
        const bookingWrapper = appointments.find(a => a.appointment.id === appointmentId);
        setRescheduleBooking(bookingWrapper);

        setShowBooking(false);

        setRescheduleAppointmentId(appointmentId);
    };

    const rescheduleBookingHandler = (bookingId: number) => {
        const bookingWrapper = bookings.find(w => w.booking?.id === bookingId);
        setRescheduleBooking(bookingWrapper);

        if (bookingWrapper && bookingWrapper.booking.appointments.length === 1) {
            setShowBooking(false);
            setRescheduleAppointmentId(bookingWrapper.booking.appointments[0].id!);
        }
    };

    const handleShowClient = (clientId: number) => {
        setShowClient(true);
        setCustomerID(clientId);
    };

    const backClientHandler = () => {
        setShowNewClient(false);
        if (showClient) {
            setShowClient(false);
        } else {
            setShowNewBooking(true);
            setIsNewBooking(false);
        }
    };

    const deleteClientHandler = () => {
        setShowNewClient(false);
        if (showClient) {
            setShowClient(false);
        } else {
            setShowNewBooking(true);
            setIsNewBooking(false);
        }
        setCustomerID(undefined);
    };

    const filteredAndSorted = sort(
        filter(view, searchText, selectedStaff, selectedAllStaff, appointments, bookings),
        sorting
    );

    const showInvoiceHandler = (appointmentId: number) => {
        const appointment = appointments.find(w => w.appointment!.id === appointmentId)!.appointment;

        if (appointment?.invoiceId) {
            setInvoiceIdToShow(appointment?.invoiceId);
        }
    };

    const declineHandler = (appointmentId: number, businessAreaId: string) => {
        const appointment = appointments.find(w => w.appointment?.id === appointmentId)!.appointment;
        if (appointment.services[0].hasReport) {
            setDecline({ appointmentId, businessAreaId });
        } else {
            dispatch(declineAppointmentThunk(apiClientWrapper, appointmentId));
        }
    };

    const undoAppointmentHandler = (appointmentId: number, statusId: number) => {
        changeStatusHandler(appointmentId, statusId);
    };

    const checkInHandler = (appointmentUuid: number) => {
        changeStatusHandler(appointmentUuid, status.CHECKED_IN);
        amplitude.track(AMPLITUDE.CTA_CHECK_IN_APPOINTMENT_BOOKING_LIST);
    };

    const startHandler = (appointmentUuid: number) => {
        changeStatusHandler(appointmentUuid, status.IN_PROGRESS);
        amplitude.track(AMPLITUDE.CTA_START_APPOINTMENT_BOOKING_LIST);
    };

    const addReportHandler = (appointmentId: number, businessAreaId: string, clientId: number) => {
        dispatch(getClientThunk(apiClientWrapper, clientId!));

        const appointmentFind = appointments.find(w => w.appointment?.id === appointmentId)!.appointment;
        const enableToAddReport = !statusToNotAddReport.includes(appointmentFind?.status.id);
        if (!appointmentFind.services[0].hasReport || enableToAddReport) {
            setShowAddReport({
                appointmentId,
                businessAreaId,
                onlyAddReport: true
            });
        }
    };

    const addReportBookingHandler = (
        bookingId: number,
        appointmentId: number,
        businessAreaId: string,
        clientId: number
    ) => {
        dispatch(getClientThunk(apiClientWrapper, clientId!));

        const bookingFind = bookings.find(w => w.booking?.id === bookingId)!.booking;
        const enableToAddReport = !statusToNotAddReport.includes(bookingFind?.appointments[0]?.status.id);
        if (!bookingFind?.appointments[0]?.services[0]?.hasReport || enableToAddReport) {
            setShowAddReport({
                appointmentId,
                businessAreaId,
                onlyAddReport: true
            });
        }
    };

    const completeHandler = (appointmentId: number, sendSms: boolean) => {
        setShowModalCompleteAppointment(false);

        dispatch(completeAppointmentThunk(apiClientWrapper, appointmentId, sendSms));
        setTrackEvent(`${process.env.REACT_APP_HUBSPOT_EVENT_APPOINTMENT_COMPLETED}`);
        amplitude.track(AMPLITUDE.CTA_COMPLETE_APPOINTMENT_BOOKING_LIST);
    };

    const completeWithModalHandler = (appointmentId: number) => {
        setCompleteReportData({
            appointmentId
        });
        setShowModalCompleteAppointment(true);
    };

    const addNewClient = () => {
        setShowNewBooking(false);
        setShowNewClient(true);
    };

    const addNewPet = (clientID: number) => {
        setShowNewBooking(false);
        setCustomerID(clientID);
        setShowNewClient(true);
        setIsNewBooking(true);
    };

    const handlerCreateClient = (pet: Pet) => {
        setCustomerID(pet.clientId);
        setCustomerName(pet.clientName);
        setPetID(pet.id);
        setShowNewClient(false);
        if (showClient) {
            setShowClient(false);
        } else {
            setShowNewBooking(true);
            setIsNewBooking(false);
        }
    };

    const clientSaveHandler = (client: ClientModel) => {
        setCustomerID(client.id);
        setIsNewBooking(false);
    };

    const editReportGroomingHandler = (appointmentId: number, businessAreaId: string, clientId: number) => {
        getClientThunk(apiClientWrapper, clientId);
        setEditReport({ appointmentId, businessAreaId, clientId });
        setShowViewReport(false);
        setShowEditReport(true);
    };

    const editReportDentalHandler = (appointmentId: number, businessAreaId: string) => {
        setEditReport({ appointmentId, businessAreaId });
        setShowViewReport(false);
        setShowEditReport(true);
    };

    const viewReportHandler = (appointmentId: number, businessAreaId: string, clientId: number) => {
        dispatch(getClientThunk(apiClientWrapper, clientId!));
        setEditReport(undefined);
        setShowEditReport(false);
        setShowViewReport(true);
        setShowReport({ appointmentId, businessAreaId, clientId, sent: true });
    };

    const backReportHandler = () => {
        setShowViewReport(false);
        setShowEditReport(false);
        setShowReport(undefined);
    };

    const onBackAddReportHandler = () => {
        setShowAddReport(undefined);
    };

    const closeReportHandler = () => {
        setShowViewReport(false);
        setShowReport(undefined);
        setEditReport(undefined);
        setShowEditReport(false);
        setShowAddReport(undefined);
    };

    const onBackBooking = () => {
        setShowBooking(false);
        setBookingId(undefined);
        setAppointmentId(undefined);
    };

    const onCancelBookingHandler = (bookingId: number) => {
        const bookingWrapper = bookings.find(w => w.booking?.id === bookingId);

        setBookingDelete(bookingWrapper?.booking);
        setAppointmentDelete(undefined);

        setShowCancelBooking(true);
    };

    const onCancelAppointmentHandler = (appointmentId: number) => {
        const appointmentWrapper = appointments.find(w => w.appointment?.id === appointmentId)!.appointment;

        setAppointmentDelete(appointmentWrapper);
        setBookingDelete(undefined);

        setShowCancelBooking(true);
    };

    const closeCancelHandler = () => {
        setShowCancelBooking(false);
    };

    const handleRemoveClient = () => {
        setCustomerID(undefined);
        setClientAdded(false);
        setPetID(undefined);
        setPetAdded(false);
    };

    const confirmCancelHandler = (
        bookingId?: number,
        appointmentId?: number,
        check?: boolean,
        cancelationReasonId?: number
    ) => {
        if (bookingId) dispatch(cancelBookingThunk(apiClientWrapper, bookingId, check, cancelationReasonId));
        if (appointmentId)
            dispatch(cancelAppointmentThunk(apiClientWrapper, appointmentId!, check, cancelationReasonId));
        setShowCancelBooking(false);
    };

    const confirmCancelRecurrentHandler = (appointmentId?: number, check?: boolean, cancelationReasonId?: number) => {
        dispatch(
            cancelRecurrentAppointmentsThunk(
                apiClientWrapper,
                appointmentId!,
                status.CANCELLED,
                check,
                cancelationReasonId
            )
        );
        setShowCancelBooking(false);
    };

    const handlerRescheduleFromDetail = (rescheduleBook: BookingWrapper, appId?: number) => {
        setRescheduleAppointmentId(appId);
        setRescheduleBooking(rescheduleBook);
    };

    const savedPetHandler = () => {
        setPetCardId(undefined);
        load(view);
    };

    const cancelBookingView = (
        <PopUpCancelBooking
            booking={bookingDelete}
            appointment={appointmentDelete}
            onClose={closeCancelHandler}
            onConfirmCancel={confirmCancelHandler}
            onConfirmCancelRecurrent={confirmCancelRecurrentHandler}
        />
    );

    const detailBookingView = (
        <BookingDetail
            bookingId={bookingId!}
            appointmentId={appointmentId!}
            onBack={onBackBooking}
            onRebook={rebookHandler}
            onReschedule={handlerRescheduleFromDetail}
            onShowClient={handleShowClient}
        />
    );

    const editReportView = (
        <OverDetail>
            <EditReport
                emailConfigured={emailConfigured}
                appointmentId={editReport?.appointmentId!}
                businessAreaId={editReport?.businessAreaId!}
                savePDF={true}
                onBack={backReportHandler}
                onClose={closeReportHandler}
                onView={() =>
                    viewReportHandler(editReport?.appointmentId!, editReport?.businessAreaId!, editReport?.clientId!)
                }
                declined={false}
                sent={editReport?.sent}
            />
        </OverDetail>
    );

    const addReportView = (
        <OverDetail>
            <CompleteReport
                appointmentId={showAddReport?.appointmentId!}
                businessAreaId={showAddReport?.businessAreaId!}
                emailConfigured={emailConfigured}
                onBack={onBackAddReportHandler}
                onClose={closeReportHandler}
                declined={false}
                onlyAddReport={showAddReport?.onlyAddReport!}
            />
        </OverDetail>
    );

    const declined = (
        <OverDetail>
            <CompleteReport
                appointmentId={decline?.appointmentId!}
                businessAreaId={decline?.businessAreaId!}
                emailConfigured={emailConfigured}
                onBack={() => setDecline(undefined)}
                onClose={closeReportHandler}
                declined={true}
            />
        </OverDetail>
    );

    const viewReport = (
        <OverDetail>
            <ViewReport
                appointmentId={showReport?.appointmentId!}
                businessAreaId={showReport?.businessAreaId!}
                onBack={backReportHandler}
                onClose={closeReportHandler}
                emailConfigured={emailConfigured}
                onEditGrooming={() =>
                    editReportGroomingHandler(
                        showReport?.appointmentId!,
                        showReport?.businessAreaId!,
                        showReport?.clientId!
                    )
                }
                onEditDental={() => editReportDentalHandler(showReport?.appointmentId!, showReport?.businessAreaId!)}
            />
        </OverDetail>
    );

    const checkoutView = (
        <OverDetail>
            <Checkout
                bookingId={checkoutBookingId!}
                appointmentIds={checkoutAppointmentIds}
                onBack={checkoutBackHandler}
                onComplete={completeCheckoutHandler}
                onShowClient={handleShowClient}
            />
        </OverDetail>
    );

    const newBooking = (
        <CreateBookingDrawer
            parentID={customerID}
            petID={petID}
            onAddClient={addNewClient}
            onAddPet={addNewPet}
            onClose={backNewBookingHandler}
            onBooked={backNewBookingHandler}
            clientAdded={clientAdded}
            petAdded={petAdded}
            onRemoveClient={handleRemoveClient}
            showTabs={false}
        />
    );

    const reschedule = (
        <CreateBookingDrawer
            bookingReschedule={rescheduleBooking}
            appointmentId={appointmentId}
            onClose={backNewBookingHandler}
            onBooked={backNewBookingHandler}
            showTabs={false}
        />
    );

    const reBook = (
        <CreateBookingDrawer
            bookingRebook={rebookBooking}
            detailView={true}
            petID={rebookBooking?.appointments[0].pet.id}
            parentID={rebookBooking?.customer.id}
            appointmentId={rebookBooking?.appointments[0].id}
            showTabs={false}
            onClose={backNewBookingHandler}
            onBooked={backNewBookingHandler}
        />
    );

    const newClient = (
        <OverFullWindow onClose={backClientHandler}>
            <Client
                hideHeader={true}
                clientId={customerID}
                isNewBooking={isNewBooking}
                onClose={backClientHandler}
                onClientSaved={clientSaveHandler}
                onPetSaved={handlerCreateClient}
                onDelete={deleteClientHandler}
            />
        </OverFullWindow>
    );

    const clientPetsForm = (
        <OverFullWindow
            onClose={() => {
                setClientID(undefined);
                setPetCardId(undefined);
            }}
        >
            <Client
                clientId={clientID}
                hideHeader={true}
                petId={petID}
                onClose={() => {
                    setClientID(undefined);
                    setPetCardId(undefined);
                }}
            />
        </OverFullWindow>
    );

    const invoice = (
        <OverDetail>
            <DisplayInvoice
                invoiceId={invoiceIdToShow!}
                onBack={() => setInvoiceIdToShow(undefined)}
            />
        </OverDetail>
    );

    const petCardView = (
        <PetCard
            petId={petCardId!}
            onSaved={savedPetHandler}
            onClose={() => setPetCardId(undefined)}
        />
    );

    const pagination = (
        <Pagination
            className={classes.pagination}
            count={filteredAndSorted.length}
            page={page}
            pageSize={pageSize}
            onPageChange={page => setPage(page)}
            onPageSizeChange={pageSize => {
                setPage(1);
                setPageSize(pageSize);
            }}
        />
    );

    const spinner = <Spinner />;

    const clientView = (
        <OverFullWindow
            hideHeader={true}
            className={classes.clientOverWindow}
            onClose={() => setViewClientId(undefined)}
        >
            <Client
                clientId={viewClientId}
                onCloseModal={() => setViewClientId(undefined)}
            />
        </OverFullWindow>
    );

    const modalCompleteAppointment = (
        <CompleteAppointmentDialog
            onCancel={() => setShowModalCompleteAppointment(false)}
            onComplete={(sendSms: boolean) => completeHandler(completeReportData?.appointmentId!, sendSms)}
        />
    );

    return (
        <Box className={classes.container}>
            <Box className={classes.headerContainer}>
                <BookingsHeader
                    period={datePeriod}
                    views={views
                        .filter(
                            v =>
                                (v.id === pendingView.id &&
                                    counter.appointments.pendings + counter.bookings.pendings > 0) ||
                                (v.id === staffIssuesView.id &&
                                    counter.appointments.withIssues + counter.bookings.withIssues > 0) ||
                                ![pendingView.id, staffIssuesView.id].includes(v.id)
                        )
                        .map(view => ({
                            id: view.id,
                            name: view.name,
                            mark: view.mark,
                            selectedBackgroundColor: view.selectedBackgroundColor
                        }))}
                    selectedViewId={view.id}
                    onSelectView={selectionViewHandler}
                    onSearch={text => {
                        setSearchText(text);
                        setPage(1);
                    }}
                    onAddAppointment={addAppointmentHandler}
                    onChangePeriod={changePeriodHandler}
                    onSelectStaff={(selectedStaffIds, all) => {
                        setSelectedStaff(selectedStaffIds);
                        setSelectedAllStaff(all);
                    }}
                    count={loading.any ? 0 : filteredAndSorted.length}
                    title={true}
                    staff={getAssignedStaff(appointments, bookings).map(staff =>
                        staff === undefined
                            ? {
                                  id: undefined,
                                  name: 'Unassigned',
                                  avatar: ''
                              }
                            : {
                                  id: staff.id,
                                  name: staff.firstName,
                                  avatar: staff.avatar
                              }
                    )}
                    showExportButton={
                        isOwner &&
                        [completedView.id, cancelledView.id, allView.id, upcomingView.id].includes(view.id) &&
                        !isEmpty(filteredAndSorted)
                    }
                    filteredAndSorted={filteredAndSorted}
                />
            </Box>
            <Box className={classes.boxBookingContainer}>
                {loading.any && spinner}
                {!loading.any && filteredAndSorted.length > 0 && (selectedStaff.length > 0 || selectedAllStaff) && (
                    <Box className={classes.tableContainer}>
                        {showModalCheckout && (
                            <ConfirmDialog
                                open={showModalCheckout}
                                question={
                                    <>
                                        This appointment is unified with another, make sure that they are all in
                                        completed status, in order to continue to checkout.
                                    </>
                                }
                                actions={
                                    <Box
                                        sx={{
                                            width: '100%',
                                            textAlign: 'center'
                                        }}
                                    >
                                        <Button
                                            variant="primary"
                                            label="Understood"
                                            onClick={() => setShowModalCheckout(false)}
                                        />
                                    </Box>
                                }
                                onCancel={() => setShowModalCheckout(false)}
                            />
                        )}
                        <Table
                            wrappers={
                                pageSize === 0
                                    ? filteredAndSorted
                                    : filteredAndSorted.slice((page - 1) * pageSize, page * pageSize)
                            }
                            view={view}
                            loading={loading.any}
                            loadingBooking={loadingBooking}
                            onShowBooking={showBookingHandler}
                            onConfirm={bookingId => confirmHandler(bookingId)}
                            onShowAppointment={showAppointmentHandler}
                            onAssignStaff={assignStaffHandler}
                            onCheckIn={checkInHandler}
                            onStart={appointmentUuid => startHandler(appointmentUuid)}
                            onComplete={(appointmentId: number, businessAreaId: string) =>
                                hasReportsPremiumAccess
                                    ? completeWithModalHandler(appointmentId)
                                    : completeHandler(appointmentId, false)
                            }
                            onCheckout={checkoutHandler}
                            onShowReport={(appointmentId: number, businessAreaId: string, clientId) =>
                                showReportHandler(appointmentId, businessAreaId, clientId)
                            }
                            onCancel={onCancelAppointmentHandler}
                            onCancelBooking={onCancelBookingHandler}
                            onNoShow={appointmentUuid => changeStatusHandler(appointmentUuid, status.NO_SHOW)}
                            onShowInvoice={showInvoiceHandler}
                            onShowPet={petId => {
                                setPetCardId(petId);
                            }}
                            onShowClient={clientId => {
                                setViewClientId(clientId);
                            }}
                            onSort={sortHandler}
                            onReschedule={rescheduleHandler}
                            onRescheduleBooking={rescheduleBookingHandler}
                            onBook={bookHandler}
                            onDecline={declineHandler}
                            onUndoAppointment={undoAppointmentHandler}
                            onShowAddReport={addReportHandler}
                            onShowAddReportBooking={addReportBookingHandler}
                        />
                    </Box>
                )}
                {!loading.any && filteredAndSorted.length === 0 && (selectedStaff.length > 0 || selectedAllStaff) && (
                    <Box>
                        <BookingsNotCreated view={view} />
                    </Box>
                )}
                {!loading.any && selectedStaff.length === 0 && !selectedAllStaff && (
                    <Box>
                        <StaffNotSelected />
                    </Box>
                )}
                {!loading.any && filteredAndSorted.length > pageSize && pagination}
            </Box>

            {rebookBooking && reBook}

            {rescheduleAppointmentId && reschedule}

            {showCancelBooking && cancelBookingView}
            {showBooking && !rescheduleAppointmentId && !showClient && detailBookingView}
            {showNewBooking && newBooking}

            {checkoutAppointmentIds.length > 0 && checkoutView}

            {(showNewClient || showClient) && newClient}
            {showEditReport && editReportView}
            {invoiceIdToShow && invoice}
            {showViewReport && viewReport}

            {petCardId && !clientID && petCardView}
            {decline && declined}
            {clientID && clientPetsForm}
            {viewClientId && clientView}
            {showAddReport && addReportView}
            {showModalCompleteAppointment && modalCompleteAppointment}
        </Box>
    );
};

export default BookingsComponent;
