import React, { useState } from 'react';
import { Box, Grid, Theme, Typography, createStyles, makeStyles, useTheme, useMediaQuery } from '@material-ui/core';
import { ClientPaymentMethod, MarketplacePaymentMethod } from '@spike/payments-model';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { faEllipsisH } from '@fortawesome/pro-regular-svg-icons';
import OptionsModal from 'components/Agreements/Modal/OptionsModal';
import useCardIcon from 'hooks/useCardIcon';

type CardType = MarketplacePaymentMethod | ClientPaymentMethod;

interface Props {
    id: number;
    active: boolean;
    onEdit?: (card: MarketplacePaymentMethod) => void;
    card: CardType | ClientPaymentMethod;
    onOpenDropdown: (id: number) => void;
    hideDefaultBadge?: boolean;
    hideEditButton?: boolean;
    onDeleteCard?: (card: ClientPaymentMethod) => void;
    showExpired?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            'height': 60,
            'width': '100%',
            'fontSize': 14,
            'fontWeight': 500,

            [theme.breakpoints.up('md')]: {
                height: 73
            },

            '&, & p': {
                fontSize: 14
            },

            '&:not(:last-child)': {
                borderBottom: 'solid 1px #D4D4D4'
            },

            [theme.breakpoints.up('sm')]: {
                '&, & p': {
                    fontSize: 16
                }
            }
        },
        leftCol: {
            display: 'flex',
            alignItems: 'center'
        },
        centerCol: {
            textAlign: 'right',

            [theme.breakpoints.down('xs')]: {
                paddingRight: 10
            },
            [theme.breakpoints.up('sm')]: {
                textAlign: 'center'
            }
        },
        rightCol: {
            columnGap: 12,
            display: 'flex',
            position: 'relative',
            justifyContent: 'flex-end'
        },
        cardNumber: {
            fontWeight: 500,
            paddingLeft: 10
        },
        defaultBadge: {
            fontSize: 14,
            lineHeight: 1.43,
            color: '#5E8677',
            borderRadius: 30,
            padding: '3.5px 11px',
            display: 'inline-block',
            backgroundColor: '#E9F0ED'
        },
        dropdownBtn: {
            border: 0,
            fontSize: 22,
            cursor: 'pointer',
            backgroundColor: 'transparent',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row'
        },
        dropdown: {
            'top': '120%',
            'zIndex': 100,
            'borderRadius': 14,
            'overflow': 'hidden',
            'position': 'absolute',
            'backgroundColor': '#fff',
            'border': 'solid 1px #000',

            '& button': {
                'border': 0,
                'width': '100%',
                'fontSize': 14,
                'fontWeight': 500,
                'lineHeight': 1.97,
                'cursor': 'pointer',
                'appearance': 'none',
                'padding': '7.5px 20px',
                'backgroundColor': '#fff',

                '& svg': {
                    marginRight: 4
                }
            }
        },

        desktop: {
            [theme.breakpoints.only('xs')]: {
                display: 'none'
            }
        },
        mobile: {
            [theme.breakpoints.up('sm')]: {
                display: 'none'
            }
        },
        columnExpired: {
            [theme.breakpoints.up('sm')]: {
                paddingLeft: '10%'
            },
            textAlign: 'left'
        },
        expired: {
            backgroundColor: '#EF4F57',
            color: '#fff',
            fontSize: 12,
            borderRadius: 30,
            padding: '4px 8px',
            fontWeight: 500,
            lineHeight: 1,
            marginLeft: 8,
            position: 'relative',
            top: -2,
            [theme.breakpoints.up('md')]: {
                fontSize: 14
            }
        }
    })
);

const isExpired = (expirationMonth: string, expirationYear: string): boolean => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;

    const cardYear = parseInt(expirationYear, 10) + 2000;
    const cardMonth = parseInt(expirationMonth, 10);

    return cardYear < currentYear || (cardYear === currentYear && cardMonth < currentMonth);
};

export const CardRow: React.FunctionComponent<Props> = props => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [showDropdown, setShowDropdown] = useState(false);

    const editHandler = () => {
        setShowDropdown(false);
        props.onEdit && props.onEdit(props.card);
    };

    const actionHandler = () => {
        if (props.onDeleteCard) {
            props.onDeleteCard(props.card as ClientPaymentMethod);
        } else {
            editHandler();
        }
    };
    const options = props.onDeleteCard
        ? [{ id: 'delete', title: 'Delete', icon: faTrash }]
        : [{ id: 'edit', title: 'Edit', icon: faPencil }];

    return (
        <Grid
            className={classes.root}
            container
            alignItems="center"
        >
            <Grid
                item
                xs={12}
                className={classes.mobile}
            >
                {props.card.holderName}
            </Grid>

            <Grid
                item
                xs={6}
                sm={3}
                className={classes.leftCol}
            >
                <img
                    src={useCardIcon(props.card.name)}
                    alt="Card icon"
                />

                <Typography className={classes.cardNumber}>•••• {props.card.lastDigits}</Typography>
            </Grid>
            <Grid
                item
                xs={5}
                sm={5}
                className={clsx(classes.centerCol, props.showExpired && classes.columnExpired)}
            >
                <Typography>
                    Exp. {props.card.expirationMonth}/{props.card.expirationYear}
                    {props.showExpired &&
                        isExpired(props.card.expirationMonth.toString(), props.card.expirationYear.toString()) && (
                            <span className={classes.expired}>Expired</span>
                        )}
                </Typography>
            </Grid>
            <Grid
                item
                xs={3}
                alignItems="center"
                className={clsx(classes.rightCol, classes.desktop)}
            >
                {props.card.holderName}
                {!props.hideDefaultBadge && <Box className={classes.defaultBadge}>Default</Box>}
            </Grid>
            <Grid
                item
                xs={1}
                alignItems="center"
                className={clsx(classes.rightCol)}
            >
                {isMobile ? (
                    <OptionsModal
                        onClick={actionHandler}
                        options={options}
                        actionComponent={
                            <button
                                type="button"
                                aria-label="Open dropdown"
                                className={classes.dropdownBtn}
                            >
                                <FontAwesomeIcon icon={faEllipsisH} />
                            </button>
                        }
                    />
                ) : (
                    <button
                        type="button"
                        aria-label="Open dropdown"
                        className={classes.dropdownBtn}
                        onClick={() => setShowDropdown(!showDropdown)}
                    >
                        <FontAwesomeIcon icon={faEllipsisH} />
                    </button>
                )}
                {!isMobile && showDropdown && (
                    <Box className={classes.dropdown}>
                        {!props.hideEditButton && (
                            <button
                                type="button"
                                aria-label="Edit card"
                                onClick={editHandler}
                                className={classes.dropdownBtn}
                            >
                                <FontAwesomeIcon icon={faPencil} />
                                <span>Edit</span>
                            </button>
                        )}
                        {props.onDeleteCard && (
                            <button
                                type="button"
                                aria-label="Delete card"
                                onClick={() => {
                                    props.onDeleteCard!(props.card as ClientPaymentMethod);
                                    setShowDropdown(false);
                                }}
                                className={classes.dropdownBtn}
                            >
                                <FontAwesomeIcon icon={faTrash} />
                                <span>Delete</span>
                            </button>
                        )}
                    </Box>
                )}
            </Grid>
        </Grid>
    );
};
